body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg g {
  fill: #3F51B5;
  fill-opacity: 0.6;
}
svg g:hover {
  fill-opacity: 1;
}
svg g:hover text {
  fill-opacity: 1;
}

svg g polygon {
  stroke: #3F51B5;
  stroke-width: 0.2;
  transition: fill-opacity .2s;
}

svg g text {
  font-size: 0.3em;
  fill: #FFFFFF;
  fill-opacity: 0.4;
  transition: fill-opacity .2s;
}
svg path {
  fill: none;
  stroke: hsl(60, 20%, 70%);
  stroke-width: 0.4em;
  stroke-opacity: 0.3;
  stroke-linecap: round;
  stroke-linejoin: round;
}